// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')



import { createApp } from 'vue'
import App from './App.vue'
import { Vue3Mq } from "vue3-mq"

const app = createApp(App)
app.use(Vue3Mq, {
	preset: 'wordpress'
})
app.mount('#app')
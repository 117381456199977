<template>
  <div class="container welcome">
    <div class="container__label">in wake we trust</div>
    <div class="container__block welcome__block">
      <mq-responsive target="small+">
      <img class="welcome__logo" alt="Miami Surf School"
        :src="require('@/assets/img/mask-group.png')">
      </mq-responsive>
      <mq-responsive target="mobile">
      <img class="welcome__logo" alt="Miami Surf School"
        :src="require('@/assets/img/mask-group-mobile.png')">
      </mq-responsive>
      <h1 class="welcome__header">miami<br>surf school</h1>
      <div class="welcome__text">ON THE CREST OF A WAVE</div>
      <a class="welcome__link" target="_blank"
        href="https://linktr.ee/miamisurfschool">
        <div class="welcome__link-text-wrapper">
          I want to book!
          <div class="welcome__link-text">I want to book!</div>
        </div>
      </a>
      <mq-responsive target="small+">
      <img class="welcome__img" alt="Miami Surf School"
        :src="require('@/assets/img/woman.webp')">
      </mq-responsive>
      <mq-responsive target="mobile">
      <img class="welcome__img" alt="Miami Surf School"
        :src="require('@/assets/img/woman-mobile.webp')">
      </mq-responsive>
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'WelcomeTag',
  components: {
    MqResponsive
  }
}
</script>

<style lang="scss">
@import '../assets/custom.scss';
.welcome{
  padding: 0 3.473%;
  max-width: none;
  width: 100%;
  &__block{
    position: relative;
    display: flex;
    padding: 5% 0 6% 2.4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
  }
  &__img{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 4%;
    max-height: 100%;
    max-width: 58%;
  }
  &__logo{
    max-width: 195px;
    @media (max-width: 1440px){
      @include a-max-width(195);
    }
    @media (max-width: 599px){
      @include a-max-width-m(195, -7);
    }
  }
  &__header{
    margin-top: 3.824%;
    font-family: Rubik One;
    font-weight: 400;
    font-size: 100px;
    line-height: 121px;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: 1440px){
      @include a-font-size(100);
      @include a-line-height(121);
    }
    @media (max-width: 599px){
      @include a-font-size-m(100, 3);
      @include a-line-height-m(121, 4);
      white-space: normal;
    }
  }
  &__text{
    font-weight: 700;
    font-size: 35px;
    text-transform: uppercase;
    padding: 1.53% 0 4.971% 0;
    white-space: nowrap;
    @media (max-width: 1440px){
      @include a-font-size(35);
    }
    @media (max-width: 599px){
      @include a-font-size-m(35, 2);
    }
  }
  &__link{
    position: relative;
    font-family: Rubik One;
    color: inherit;
    background-color: #EEC52A;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 26px;
    padding: 2.447% 6.059% 1.53% 6.059%;
    border-bottom: solid 10px #DF3931;
    @media (max-width: 1440px){
      @include a-font-size(22);
      border-bottom: solid ceil(10 * $brake-point-1)+px #DF3931;
    }
    @media (max-width: 1000px){
      border-bottom: solid ceil(10 * $brake-point-2)+px #DF3931;
    }
    @media (max-width: 599px){
      @include a-font-size-m(26, 2);
      border-bottom: solid ceil(10 * $brake-point-3)+px #DF3931;
    }
    &::after{
      position: absolute;
      content: "";
      top: 100%;
      left: 0;
      right: 0;
      bottom: -1px;
      background-color: #DF3931;
      transition: all .2s linear;
    }
    &:hover{
      &::after{
        top: 0;
      }
    }
  }
  &__link-text-wrapper{
    position: relative;
  }
  &__link-text{
    position: absolute;
    z-index: 1;
    top: 0;
  }
}
th, td {
  border: solid 1px black ;
}
.container{
  &__block{
    background-color: #DBD9CA;
  }
  &__label{
    font-size: 60px;
    line-height: 44px;
    text-transform: uppercase;
    color: #DBD9CA;
    font-weight: 900;
    margin-left: -3px;//-
    @media (max-width: 1440px){
      @include a-font-size(60);
      @include a-line-height(44);
      margin-left: -2px;
      margin-bottom: -1px;
    }
    @media (max-width: 1000px){
      margin-left: -2px;
      margin-bottom: -1px;
    }
    @media (max-width: 599px){
      @include a-font-size-m(60, 2);
      @include a-line-height-m(44, 2);
      margin-left: -0.6px;
      margin-bottom: -2px;
    }
  }
}
</style>

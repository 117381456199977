<template>
  <div class="contact">
    <div class="contact__block" id="contact">
      <div class="contact__left">
        <div class="contact__header">
          <mq-responsive target="small+">
          <img :src="require('@/assets/img/rectangles.webp')" alt="Miami Surf School">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img :src="require('@/assets/img/rectangles-mobile.webp')" alt="Miami Surf School">
          </mq-responsive>
          <div class="contact__header-text">How to find us?</div>
        </div>
        <div class="contact__text">
          2022 Collins Ave,<br>
          Miami Beach,<br>
          FL 33140
        </div>
        <a
          class="contact__link"
          target="_blank"
          href="https://maps.app.goo.gl/VjbaH8H8cgCdRuBE8?g_st=ic"
        >
          <span class="contact__link-text">
            start guidance using<br>
            Google maps
          </span>
          <mq-responsive target="small+">
            <img
              :src="require('@/assets/img/yandex.png')"
              class="contact__yandex-img"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
            :src="require('@/assets/img/yandex-mobile.png')"
            class="contact__yandex-img"
            alt="Miami Surf School"
          >
          </mq-responsive>
        </a>
        <div class="contact__text">
          feel free to call us
        </div>
        <div class="contact__number">
          <a href="tel:+19177174838">+1 (917) 717-48-38</a>
        </div>
      </div>
      <div class="contact__right">
        <mq-responsive target="small+" class="contact__tape-orange-img-wrapper">
        <img class="contact__tape-orange-img" alt="Miami Surf School"
          :src="require('@/assets/img/tape-orange-2.webp')">
        </mq-responsive>
        <mq-responsive target="mobile" class="contact__tape-orange-img-wrapper">
        <img class="contact__tape-orange-img" alt="Miami Surf School"
          :src="require('@/assets/img/tape-orange-2-mobile.webp')">
        </mq-responsive>
        <div class="contact__map">
          <a target="_blank"
            href="https://maps.app.goo.gl/VjbaH8H8cgCdRuBE8?g_st=ic"
          >
            <mq-responsive target="small+">
            <img class="contact__map-img" alt="Miami Surf School"
              :src="require('@/assets/img/map.png')">
            </mq-responsive>
            <mq-responsive target="mobile">
            <img class="contact__map-img" alt="Miami Surf School"
              :src="require('@/assets/img/map-mobile.png')">
            </mq-responsive>
          </a>
        </div>
        <div class="contact__links">
          <a target="_blank" href="https://instagram.com/miami_wakesurf_school?igshid=MDM4ZDc5MmU="><i class="fa-brands fa-instagram"/></a>
        </div>
      </div>
    </div>
    <div class="contact__tagline">
      If you want<br>
      to be cool come to<br>
      miami surf school
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'ContactTag',
  components: {
    MqResponsive
  }
}
</script>

<style lang="scss">
@import '../assets/custom.scss';
.contact {
  display: flex;
  flex-direction: column;
  padding-top: 4.167%;
  padding-bottom: 4.167%;
  &__block {
    display: flex;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5.556%;
  }
  &__header {
    position: relative;
    img{
      margin-left: -8.74%;
      @media (max-width: 1440px){
        @include a-max-width(229);
      }
    }
    margin-bottom: 13.268%;
  }
  &__header-text {
    position: absolute;
    top: 27.1%;
    font-weight: 700;
    font-size: 59px;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: 1440px){
      @include a-font-size(59);
    }
    @media (max-width: 599px){
      @include a-font-size-m(59,2);
    }
  }
  &__text {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(25);
      @include a-line-height(30);
    }
    @media (max-width: 599px){
      @include a-font-size-m(25,3);
      @include a-line-height-m(30,3);
    }
  }
  &__link {
    display: flex;
    align-items: center;
    margin: 30px 0;
    margin-left: -80px;
    padding: 10px 60px 10px 80px;
    background-color: #DDDBCD;
    cursor: pointer;
    color: inherit;
    transition: background-color .2s linear;
    &:hover{
      background-color: #a3a194;
    }
    @media (max-width: 1440px){
      @include a-margin(30, 0, 30, -80);
      @include a-padding(10, 60, 10, 80);
    }
  }
  &__link-text{
    font-weight: 900;
    font-size: 25px;
    line-height: 31px;
    text-transform: uppercase;
    margin-right: 30px;
    @media (max-width: 1440px){
      @include a-font-size(25);
      @include a-line-height(31);
      @include a-margin(0, 30, 0, 0);
    }
    @media (max-width: 599px){
      @include a-font-size-m(25,3);
      @include a-line-height-m(31,2);
    }
  }
  &__number {
    margin-top: 40px;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    a{
    color: inherit;
    }
    @media (max-width: 1440px){
      @include a-font-size-m(25,10);
      @include a-line-height(30);
      @include a-margin(40, 0, 0, 0);
    }
  }
  &__support-wrapper {
    display: flex;
    align-items: center;
    margin-top: 50px;
    @media (max-width: 1440px){
      margin-top: ceil(50 * $brake-point-1)+px;
      @include a-margin(50, 0, 0, 0);
    }
    @media (max-width: 599px){
      display: none;
    }
  }
  &__support {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    @media (max-width: 1440px){
      @include a-margin(0, 0, 0, 60);
    }
  }
  &__support-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    @media (max-width: 1440px){
      @include a-font-size(14);
      @include a-line-height(17);
    }
    @media (max-width: 599px){
      @include a-font-size-m(14,2);
      @include a-line-height(17);
    }
  }
  &__support-link {
    margin-top: 15px;
    text-align: center;
    padding: 6px 0;
    font-family: Rubik One;
    background-color: #DF3931;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: inherit;
    transition: all .2s linear;
    &:hover{
      background-color: #fe681b;
    }
    @media (max-width: 1440px){
      @include a-font-size(16);
      @include a-line-height(32);
      @include a-margin(16, 0, 0, 0);
      @include a-padding(6, 0, 6, 0);
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
    max-width: 63%;
  }
  &__map {
    margin-top: -10%;
    width: 100%;
    display: flex;
    justify-content: center;
    a{
      max-width: 77%;
    }
  }
  &__map-img{
    max-width: 100%;
  }
  &__links {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 60px;
    margin-top: 35px;
    padding-right: 12%;
    z-index: 1;
    @media (max-width: 1440px){
      @include a-font-size(50);
      @include a-margin(35, 0, 0, 0);
    }
    @media (max-width: 599px){
      @include a-font-size-m(50,5);
    }
    a{
      color: inherit;
      i{
        transition: all .2s linear;
      }
      .fa-facebook:hover{
        color: #1877f2;
      }
      .fa-vk:hover{
        color: #5281b9;
      }
      .fa-instagram:hover{
        color: #e92266;
      }
      .fa-telegram:hover{
        color: #239cd6;
      }
    }
    a+a{
      margin-left: 55px;
      @media (max-width: 1440px){
        @include a-margin(0, 0, 0, 55);
      }
    }
  }
  &__tagline {
    color: #DDDBCD;
    font-weight: 900;
    font-size: 70px;
    line-height: 87px;
    padding-left: 72px;
    margin-top: -5.3%;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(70);
      @include a-line-height(87);
      @include a-padding(0, 0, 0, 72);
    }
  }
  &__yandex-img{
    @media (max-width: 1440px){
      @include a-max-width(93);
    }
  }
  &__tape-orange-img-wrapper{
    margin-top: 15%;
    max-width: 48%;
  }
  &__tape-orange-img{
    max-width: 100%;
  }
  &__lighthouse-img{
    @media (max-width: 1440px){
      @include a-max-width(85);
    }
  }
}
</style>

<template>
  <div class="content">
    <mq-responsive target="small+" class="header">
      <div @click="scrollTo('price')">prices</div>
      <div @click="scrollTo('booking')">book your spot now</div>
      <div @click="scrollTo('contact')">Contacts</div>
    </mq-responsive>
    <mq-responsive target="mobile" class="mobile-header">
      <transition name="fade">
        <img class="mobile-header__logo" v-if="open"
          :src="require('@/assets/img/mask-group-mobile.png')">
      </transition>
      <div class="mobile-header__menu-icon" @click="toogleOpen">
        <div class="mobile-header__icon" :class="{'open': open}">
        </div>
      </div>
    </mq-responsive>
    <welcome-tag/>
    <description-tag />
    <price-tag />
    <booking-tag/>
    <contact-tag/>
    <transition name="height">
      <div class="mobile-menu" v-if="open">
        <div class="mobile-menu__content">
          <div class="mobile-menu__items">
            <div class="mobile-menu__item" @click="menuScrollTo('price')">prices</div>
            <div class="mobile-menu__item" @click="menuScrollTo('booking')">book your spot now</div>
            <div class="mobile-menu__item" @click="menuScrollTo('contact')">Contacts</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
import WelcomeTag from './components/WelcomeTag.vue'
import DescriptionTag from './components/DescriptionTag.vue'
import PriceTag from './components/PriceTag.vue'
import BookingTag from './components/BookingTag.vue'
import ContactTag from './components/ContactTag.vue'

export default {
  name: 'App',
  components: {
    WelcomeTag,
    DescriptionTag,
    PriceTag,
    BookingTag,
    ContactTag,
    MqResponsive,
  },
  data(){
    return{
      open: false,
    }
  },
  methods:{
    scrollTo(text){
      const el = document.getElementById(text);
      el.scrollIntoView({behavior: "smooth"});
    },
    menuScrollTo(text){
      const el = document.getElementById(text);
      let app = document.getElementById('app');
      app.style.overflow = 'auto';
      this.open = false;
      setTimeout(()=>{
        el.scrollIntoView({behavior: "smooth"});
      }, 300)
    },
    toogleOpen(){
      this.open = !this.open;
      let el = document.getElementById('app');
      el.style.overflow = this.open ? 'hidden' : 'auto';
    }
  },
}
</script>

<style lang="scss">
@import 'assets/null.css';
@import 'assets/custom.scss';
@import 'assets/fonts/gilroy/stylesheet.css';
@import 'assets/fonts/rubik-one/stylesheet.css';
body{
 display: flex;
 flex-direction: column; 
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.height-enter-active,
.height-leave-active {
  transition: all 0.5s ease;
}

.height-enter-active.height-enter-from,
.height-leave-active.height-leave-to {
  bottom: 100%;
}

#app {
  display: flex;
  justify-content: center;
  flex:  1;
  background-color: #EDEAE0;
  font-family: Gilroy;
  color: #333333;
  @media (max-width: 599px){
    min-width: 350px;
  }
}
.content{
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1440px;
  // @media (max-width: 1440px){
  //   max-width: 1000px;
  // }
  // @media (max-width: 1000px){
  //   max-width: 600px;
  // }
  @media (max-width: 599px){
    max-width: none;
    min-width: 350px;
  }
}
.mobile-header{
  display: flex;
  margin-bottom: 1px;
  justify-content: flex-end;
  padding: 15px 15px 8px 20px;
  &__logo{
    position: absolute;
    top: 7px;
    left: 15px;
    max-width: 38px;
  }
  &__menu-icon{
    padding: 10px 10px;
  }
  &__icon{
    position: relative;
    width: 20px;
    height: 2px;
    background-color: #533557;
    border-radius: 5px;
    transition: all .5s ease;
    &::before,
    &::after{
      top: 0;
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      background: #533557;
      border-radius: 5px;
      transition: all .5s ease;
    }
    &::before{
      transform: translateY(-6px);
    }
    &::after{
      transform: translateY(6px);
    }
    &.open{
      transform: translateX(-50px);
      background: transparent;
      &::before{
        transform: rotate(45deg) translate(35px, -35px);
      }
      &::after{
        transform: rotate(-45deg) translate(35px, 35px);
      }
    }
  }
}
.mobile-menu{
  position: absolute;
  overflow: hidden;
  top: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #EDEAE0;
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__items {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 100px 20px 0 0;
  }
  &__item {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    text-transform: uppercase;
    margin-top: 4%;
    padding: 10px 20px;
    border-radius: 4px;
    &:hover{
      background-color: #dbd9ca;
    }
  }
  &__support-wrapper {
    padding-top: 100px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 33px;
  }
  &__lighthouse-img {
    max-width: 29px;
    align-self: flex-start;
  }
  &__support {
    display: flex;
    flex-direction: column;
  }
  &__support-text {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
  &__support-link {
    margin-top: 15px;
    text-align: center;
    padding: 8px 30px 4px 30px;
    font-family: Rubik One;
    background-color: #EEC52A;
    border-bottom: solid 5px #DF3931;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: inherit;
    transition: all .2s linear;
    text-transform: uppercase;
    &:hover{
      background-color: #DF3931;
    }
  }
}
.header{
  display: flex;
  justify-content: flex-end;
  padding: 6.25% 3.473% 0 0;
  @media (max-width: 599px){
    display: none;
  }
  div{
    color: inherit;
    padding: 0.725% 1.45%;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .2s linear;
    border-radius: 4px;
    user-select: none;
    &:hover{
      background-color: #dbd9ca;
    }
    @media (max-width: 1440px){
      @include a-font-size(20);
      @include a-line-height(24);
    }
  }
}
</style>

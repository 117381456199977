<template>
  <div class="container booking">
    <div class="container__label booking__label" id="booking">wake surf is the best</div>
    <div class="container__block booking__block">
      <div class="booking__top">
        <div class="booking__left">
          <span class="booking__question">
            Do you want to ride on your day off?<br>
            Or maybe on a weekday?<br>
            Or even today?<br>
            are you ready right now?
          </span>
          <span class="booking__caption">
            Don’t hesitate to Call us!
          </span>
          <div class="booking__phone">
            <mq-responsive target="small+">
            <img :src="require('@/assets/img/rectangle.webp')" alt="Miami Surf School">
            </mq-responsive>
            <mq-responsive target="mobile">
            <img :src="require('@/assets/img/rectangle-mobile.webp')" alt="Miami Surf School">
            </mq-responsive>
            <a class="booking__number" href="tel:+19177174838">+1 (917) 717-48-38</a>
          </div>
          <a class="booking__link" target="_blank" href="https://linktr.ee/miamisurfschool">
            Book your spot now
          </a>
        </div>
        <div class="booking__right">
          <div class="booking__right-wrapper">
            <mq-responsive target="small+" class="booking__img-tape-wrapper">
            <img class="booking__img-tape" :src="require('@/assets/img/tape-gray.webp')" alt="Miami Surf School">
            </mq-responsive>
            <mq-responsive target="mobile" class="booking__img-tape-wrapper">
            <img class="booking__img-tape" :src="require('@/assets/img/tape-gray-mobile.webp')" alt="Miami Surf School">
            </mq-responsive>
            <mq-responsive target="small+">
            <img class="booking__img-girl" :src="require('@/assets/img/girl.png')" alt="Miami Surf School">
            </mq-responsive>
            <mq-responsive target="mobile">
            <img class="booking__img-girl" :src="require('@/assets/img/girl.png')" alt="Miami Surf School">
            </mq-responsive>
          </div>
        </div>
      </div>
      <div class="booking__middle">
        <span class="booking__text">
          Furthermore in msS The coolest and brightest<br>
          boards from our partner - Preacher surf,<br>
          a manufacturer of custom wakesurfs.
        </span>
      </div>
      <div class="booking__bottom">
        <div class="booking__img-row">
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--1"
              :src="require('@/assets/img/bottom-img-1.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--1"
              :src="require('@/assets/img/bottom-img-1.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--2"
              :src="require('@/assets/img/bottom-img-2.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--2"
              :src="require('@/assets/img/bottom-img-2.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--3"
              :src="require('@/assets/img/bottom-img-3.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--3"
              :src="require('@/assets/img/bottom-img-3.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--4"
              :src="require('@/assets/img/bottom-img-4.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--4"
              :src="require('@/assets/img/bottom-img-4.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--5"
              :src="require('@/assets/img/bottom-img-5.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--5"
              :src="require('@/assets/img/bottom-img-5.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--6"
              :src="require('@/assets/img/bottom-img-6.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--6"
              :src="require('@/assets/img/bottom-img-6.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--7"
              :src="require('@/assets/img/bottom-img-7.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--7"
              :src="require('@/assets/img/bottom-img-7.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="small+">
            <img
              class="booking__bottom-img--8"
              :src="require('@/assets/img/bottom-img-8.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
          <mq-responsive target="mobile">
            <img
              class="booking__bottom-img--8"
              :src="require('@/assets/img/bottom-img-8.png')"
              alt="Miami Surf School"
            >
          </mq-responsive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'BookingTag',
  components: {
    MqResponsive
  }
}
</script>

<style lang="scss">
@import '../assets/custom.scss';
.booking {
  padding-left: 3.473%;
  padding-right: 3.473%;
  padding-top: 7.639%;
  width: 100%;
  max-width: none;
  &__label{
    font-size: 100px;
    line-height: 70px;
    font-weight: 800;
    @media (max-width: 1440px){
      @include a-font-size(100);
      @include a-line-height(70);
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
  }
  &__top {
    display: flex;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 11.195% 0 3.732% 5.225%;
    @media (max-width: 599px){
      padding: ceil(150 * $brake-point-3)-10+px 0 ceil(50 * $brake-point-3)+px 3vw;
    }
  }
  &__question {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    &--bold{
      font-weight: 900;
    }
    @media (max-width: 1440px){
      @include a-font-size(30);
      @include a-line-height(36);
    }
  }
  &__caption {
    margin-top: 9.747%;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(34);
      @include a-line-height(42);
    }
  }
  &__phone {
    margin-top: 2.25%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      @media (max-width: 1440px){
        @include a-max-width(667);
      }
    }
  }
  &__number{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 900;
    font-size: 54px;
    text-align: center;
    text-transform: uppercase;
    color: inherit;
    padding: 15px;
    @media (max-width: 1440px){
      @include a-font-size(54);
    }
  }
  &__link {
    margin-top: 5.249%;
    font-weight: 900;
    font-size: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #dfddcf;
    background-color: #25285E;
    padding: 6.747% 7.497%;
    transition: background-color .2s linear;
    &:hover{
      background-color: #2128a8;
    }
    @media (max-width: 1440px){
      @include a-font-size(34);
    }
    @media (max-width: 599px){
      @include a-font-size-m(34, 1);
    }
  }
  &__right {
    position: relative;
    padding: 5.225% 5.225% 0 5.225%;
    flex: 1;
    display: flex;
    align-items: flex-start;
    @media (max-width: 599px){
      align-items: center;
      padding: 16px 3vw 10px 3vw;
      justify-content: center;
    }
  }
  &__right-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__img-girl {
    max-width: 100%;
    position: relative;
    @media (max-width: 1440px){
      @include a-max-width(467);
    }
  }
  &__img-tape-wrapper {
    position: absolute;
    max-width: 47%;
    top: 93%;
    right: 10%;
  }
  &__img-tape {
    max-width: 100%;
    @media (max-width: 599px){
      max-width: 93%;
    }
  }
  &__middle {
    padding-left: 2.986%;
    padding-top: 1.493%;
  }
  &__text {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(30);
      @include a-line-height(46);
    }
    @media (max-width: 599px){
      font-size: ceil(30 * $brake-point-3)+0.5+px;
      line-height: ceil(36 * $brake-point-3)+px;
    }
    &--bold{
      font-weight: 900;
    }
  }
  &__bottom {
    position: relative;
    padding-top: 30%;
    padding-bottom: 30%;
    img {
      position: absolute;
    }
  }
  &__img-row {
    display: flex;
    justify-content: space-between;
    padding: 0.747% 4.478%;
    @media (max-width: 599px){
      justify-content: space-around;
      padding: 0;
    }
  }
  &__bottom-img--1 {
    max-width: 310px;
    left: 950px;
    top: 300px;
    z-index: 2;
    @media (max-width: 1440px){
      @include a-max-width(310);
      @include a-left(950);
      @include a-top(300);
    }
  }
  &__bottom-img--2 {
    max-width: 270px;
    left: 50px;
    top: 350px;
    z-index: 2;
    @media (max-width: 1440px){
      @include a-max-width(270);
      @include a-left(50);
      @include a-top(350);
    }
  }
  &__bottom-img--3 {
    max-width: 195px;
    left: 475px;
    top: 450px;
    z-index: 2;
    @media (max-width: 1440px){
      @include a-max-width(195);
      @include a-left(475);
      @include a-top(450);
    }
  }
  &__bottom-img--4 {
    max-width: 269px;
    left: 560px;
    top: 50px;
    z-index: 2;
    @media (max-width: 1440px){
      @include a-max-width(269);
      @include a-left(560);
      @include a-top(50);
    }
  }
  &__bottom-img--5 {
    max-width: 226px;
    left: 160px;
    top: 550px;
    @media (max-width: 1440px){
      @include a-max-width(226);
      @include a-left(160);
      @include a-top(550);
    }
  }
  &__bottom-img--6 {
    max-width: 286px;
    left: 920px;
    top: 160px;
    @media (max-width: 1440px){
      @include a-max-width(286);
      @include a-left(920);
      @include a-top(160);
    }
  }
  &__bottom-img--7 {
    max-width: 710px;
    left: 20px;
    top: 100px;
    @media (max-width: 1440px){
      @include a-max-width(710);
      @include a-left(20);
      @include a-top(100);
    }
  }
  &__bottom-img--8 {
    max-width: 782px;
    left: 550px;
    top: 250px;
    @media (max-width: 1440px){
      @include a-max-width(782);
      @include a-left(550);
      @include a-top(250);
    }
  }
}
</style>

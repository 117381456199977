<template>
  <div class="description">
    <div class="description__top">
      <mq-responsive target="small+" class="description__tape-orange-img-wrapper">
      <img class="description__tape-orange-img" alt="Miami Surf School"
        :src="require('@/assets/img/tape-orange.webp')">
      </mq-responsive>
      <mq-responsive target="mobile" class="description__tape-orange-img-wrapper">
      <img class="description__tape-orange-img" alt="Miami Surf School"
        :src="require('@/assets/img/tape-orange-mobile.webp')">
      </mq-responsive>
      <div class="description__header">
        <div class="description__header-wrapper">
          <mq-responsive target="small+" class="description__tape-blue-wrapper">
          <img class="description__tape-blue-img" alt="Miami Surf School"
            :src="require('@/assets/img/tape-blue.webp')">
          </mq-responsive>
          <mq-responsive target="mobile" class="description__tape-blue-wrapper">
          <img class="description__tape-blue-img" alt="Miami Surf School"
            :src="require('@/assets/img/tape-blue-mobile.webp')">
          </mq-responsive>
          <span>We invite YOU TO DO IT WITH US!</span>
          <mq-responsive target="small+" class="description__tape-blue-wrapper
            description__tape-blue-wrapper--rotate">
          <img class="description__tape-blue-img
            description__tape-blue-img--rotate" alt="Miami Surf School"
            :src="require('@/assets/img/tape-blue.webp')">
          </mq-responsive>
          <mq-responsive target="mobile" class="description__tape-blue-wrapper
            description__tape-blue-wrapper--rotate">
          <img class="description__tape-blue-img
            description__tape-blue-img--rotate" alt="Miami Surf School"
            :src="require('@/assets/img/tape-blue-mobile.webp')">
          </mq-responsive>
        </div>
      </div>
      <div class="description__caption">
        Wakesurfing in Miami is steadily gaining<br>
        more and more popularity every year.
      </div>
    </div>
    <div class="description__middle">
      <div class="description__middle-left-wrapper">
        <div class="description__middle-left">
          <mq-responsive target="small+">
          <img class="description__tape-purple-img" alt="Miami Surf School"
            :src="require('@/assets/img/tape-purple.webp')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="description__tape-purple-img" alt="Miami Surf School"
            :src="require('@/assets/img/tape-purple-mobile.webp')">
          </mq-responsive>
          <mq-responsive target="small+">
          <img class="description__man-img" alt="Miami Surf School"
            :src="require('@/assets/img/man.webp')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="description__man-img" alt="Miami Surf School"
            :src="require('@/assets/img/man-mobile.webp')">
          </mq-responsive>
          <mq-responsive target="small+">
          <img class="description__circles-img" alt="Miami Surf School"
            :src="require('@/assets/img/circles.webp')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="description__circles-img" alt="Miami Surf School"
            :src="require('@/assets/img/circles-mobile.webp')">
          </mq-responsive>
        </div>
      </div>
      <div class="description__middle-right-wrapper">
        <div class="description__middle-right">
          <div class="description__text-block description__text-block--top">
            <div class="description__text">
              We guarantee, having tried at<br>
              least once, you will not remain<br>
              indifferent to this sport - it will<br>
              remain with you for many years!
            </div>
            <mq-responsive target="small+">
              <img class="description__mask-group-img" alt="Miami Surf School"
                :src="require('@/assets/img/mask-group.png')">
            </mq-responsive>
          </div>
          <div class="description__text-block description__text-block--bottom">
            <div class="description__text">
              The training and emotions you<br>
              get can't be compared<br>
              with any fitness club, and you<br>
              do not need any special skills.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="description__bottom">
      <div class="description__bottom-left">
        <mq-responsive target="small+">
        <img class="description__tape-yellow-img" alt="Miami Surf School"
          :src="require('@/assets/img/tape-yellow.webp')">
        </mq-responsive>
        <mq-responsive target="mobile">
        <img class="description__tape-yellow-img" alt="Miami Surf School"
          :src="require('@/assets/img/tape-yellow-mobile.webp')">
        </mq-responsive>
        <div class="description__bottom-text">
          Our experienced pilots and<br>
          instructors will carefully<br>
          explain and help you get<br>
          comfortable on the board<br>
          during the session!
        </div>
        <mq-responsive target="mobile">
          <img class="description__mask-group-img" alt="Miami Surf School"
            :src="require('@/assets/img/mask-group-mobile.png')">
        </mq-responsive>
      </div>
      <div class="description__bottom-right">
        <mq-responsive target="small+" class="description__tape-white-img-wrapper">
        <img class="description__tape-white-img" alt="Miami Surf School"
          :src="require('@/assets/img/tape-white.webp')">
        </mq-responsive>
        <mq-responsive target="mobile" class="description__tape-white-img-wrapper">
        <img class="description__tape-white-img" alt="Miami Surf School"
          :src="require('@/assets/img/tape-white-mobile.webp')">
        </mq-responsive>
        <div class="description__coach-card description__coach-card--card-1">
          <mq-responsive target="small+">
          <img class="description__coach-img" alt="Miami Surf School"
            :src="require('@/assets/img/coach-1.webp')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="description__coach-img" alt="Miami Surf School"
            :src="require('@/assets/img/coach-1-mobile.webp')">
          </mq-responsive>
        </div>
        <div class="description__coach-card description__coach-card--card-2">
          <mq-responsive target="small+">
          <img class="description__coach-img" alt="Miami Surf School"
            :src="require('@/assets/img/coach-2.png')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="description__coach-img" alt="Miami Surf School"
            :src="require('@/assets/img/coach-2-mobile.png')">
          </mq-responsive>
        </div>
        <div class="description__coach-card description__coach-card--card-3">
          <mq-responsive target="small+">
          <img class="description__coach-img" alt="Miami Surf School"
            :src="require('@/assets/img/coach-3.webp')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="description__coach-img" alt="Miami Surf School"
            :src="require('@/assets/img/coach-3-mobile.webp')">
          </mq-responsive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'DescriptionTag',
  components: {
    MqResponsive
  },
  computed: {
    mobile(){
      return false;
    }
  }
}
</script>

<style lang="scss">
@import '../assets/custom.scss';
.description{
  position: relative;
  //  ---------------------------
  &__top{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 5.556% 0 0;
    @media (max-width: 599px){
      flex-direction: column;
    }
  }
  &__tape-orange-img-wrapper{
    margin-top: 4.412%;
    max-width: 23%;
    @media (max-width: 599px){
      max-width: 32%;
    }
  }
  &__tape-orange-img{
    max-width: 100%;
  }
  &__hello-wrapper{
    display: flex;
    justify-content: flex-end;
  }
  &__caption{
    position: absolute;
    top: 23.282%;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-left: 5.556%;
    @media (max-width: 1440px){
      @include a-font-size(32);
      @include a-line-height(39);
    }
    @media (max-width: 599px){
      top: 18%;
      @include a-font-size-m(32, 7);
      @include a-line-height-m(39, 8);
    }
  }
  &__header{
    margin-top: 4.412%;
    margin-left: 5.883%;
    display: flex;
    font-weight: 900;
    font-size: 51px;
    white-space: nowrap;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(51);
    }
    @media (max-width: 599px){
      @include a-font-size-m(51, 5);
      margin-top: 8%;
      align-self: center;
    }
  }
  &__header-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span{
      padding: 1.172% 0;
    }
    @media (max-width: 599px){
     margin-left: 0%;
    }
  }
  &__tape-blue-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-right: 19%;
    &--rotate{
      margin-right: 21%;
    }
  }
  &__tape-blue-img{
    &--rotate{
      transform: rotate(180deg);
    }
    @media (max-width: 1440px){
      @include a-max-width(266);
    }
    @media (max-width: 599px){
      @include a-max-width-m(266,19);
    }
  }


  &__middle {
    position: relative;
    display: flex;
    margin: -5.556% 5.556% 0 5.556%;
  }
  &__middle-left-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 36.8%;
  }
  &__middle-left {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__tape-purple-img {
    position: absolute;
    bottom: -11%;
    left: -6%;
    transform: rotate(160deg);
    max-width: 50%;
    @media (max-width: 599px){
      bottom: -13%;
      left: 5%;
      transform: rotate(180deg);
    }
  }
  &__man-img {
    max-width: 100%;
    position: relative;
  }
  &__circles-img {
    position: absolute;
    bottom: 15%;
    left: 92%;
    max-width: 38%;
    @media (max-width: 599px){
      bottom: 32%;
    }
  }

  &__middle-right-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
    margin-left: 5%;
  }
  &__middle-right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    @media (max-width: 599px){
      align-items: center;
      width: auto;
    }
  }
  &__text-block{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 599px){
      justify-content: center;
      width: auto;
      padding-left: 4vw;
    }
    &--bottom{
      justify-content: flex-end;
      margin-right: 11%;
      margin-bottom: 5.37%;
      margin-top: 18.793%;
      @media (max-width: 599px){
        margin-right: 0;
        margin-bottom: 0;
        margin-top: 10vw;
        margin-left: -10px;
      }
    }
  }
  &__text{
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: 1440px){
      @include a-font-size(28);
      @include a-line-height(34);
    }
    @media (max-width: 1440px){
      @include a-font-size-m(28, 3);
      @include a-line-height-m(34, 3);
    }
  }
  &__mask-group-img {
    max-width: 183px;
    @media (max-width: 1440px){
      @include a-max-width(183);
    }
    @media (max-width: 599px){
      @include a-max-width-m(250, 0);
      position: absolute;
      top: 14%;
      left: 30%;
    }
  }

  &__bottom {
    display: flex;
    margin-top: 6%;
  }
  &__bottom-left {
    display: flex;
    width: 46.2%;
    padding-top: 24%;
    padding-bottom: 18%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 599px){
      justify-content: flex-end;
      padding-top: 42%;
      padding-bottom: 0%;
    }
  }
  &__tape-yellow-img {
    margin-top: 10%;
    max-width: 60%;
    @media (max-width: 599px){
      max-width: 70%;
    }
  }
  &__bottom-text {
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    text-transform: uppercase;
    margin-top: -48%;
    margin-left: 12%;
    white-space: nowrap;
    @media (max-width: 1440px){
      @include a-font-size(35);
      @include a-line-height(42);
    }
    @media (max-width: 599px){
      font-size: 12px;
      line-height: 15px;
      z-index: 2;
      bottom: 20px;
      position: absolute;
    }
    &--bold {
      font-weight: 900;
    }
  }
  &__bottom-right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    // margin-left: 7%;
    @media (max-width: 599px){
      padding-bottom: 30px;
    }
  }
  &__tape-white-img-wrapper {
    position: absolute;
    max-width: 100%;
    transform: translate(0, -50%);
    top: 48%;
    @media (max-width: 599px){
      top: 53.5%;
    }
  }
  &__tape-white-img {
    max-width: 100%;
  }
  &__coach-card {
    position: absolute;
    @media (max-width: 599px){
      max-width: 50%;
    }
    &--card-1{
      top: 0;
      left: 12%;
      @media (max-width: 599px){
        left: 3%;
      }
    }
    &--card-2{
      top: 15%;
      right: 10%;
      z-index: 2;
      @media (max-width: 599px){
        right: 6%;
        top: 15%;
      }
    }
    &--card-3{
      bottom: 5%;
      right: 50%;
      transform: translate(50%,0);
      @media (max-width: 599px){
        bottom: 0%;
      }
    }
  }
  &__coach-name {
    position: relative;
    text-align: right;
    font-weight: 900;
    font-size: 28px;
    line-height: 35px;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(28);
      @include a-line-height(35);
    }
    @media (max-width: 599px){
      font-size: 12px;
      line-height: ceil(35 * $brake-point-3)+px;
    }
    &--green {
      color: #45AA26;
      margin-top: 0%;
      text-align: left;
    }
    &--red {
      color: #DE4F19;
      margin-right: 14%;
      @media (max-width: 599px){
        margin-right: 4%;
        margin-top: 4%;
      }
    }
    &--orange {
      color: #ECA704;
      margin-top: -10%;
      margin-right: -10%;
      @media (max-width: 599px){
        margin-top: 0%;
      }
    }
  }
  &__coach-img {
    position: relative;
    max-width: 325px;
    @media (max-width: 1440px){
      @include a-max-width(325);
    }
    @media (max-width: 599px){
      max-width: 100%;
    }
  }
}
</style>

<template>
  <div class="price">
    <div class="price__header" id="price">
      <mq-responsive target="small+">
      <img class="price__header-img" alt="Miami Surf School"
        :src="require('@/assets/img/price-sheet.webp')">
      </mq-responsive>
      <mq-responsive target="mobile">
      <img class="price__header-img" alt="Miami Surf School"
        :src="require('@/assets/img/price-sheet-mobile.webp')">
      </mq-responsive>
      <span class="price__header-text">Prices</span>
    </div>
    <div class="price__table">
      <div class="price__row price__row--row-1">
        <div class="price__cell price__cell--cell-1-1">
          <span class="price__boat-name price__boat-name--name-1">mastercraft</span>
          <mq-responsive target="small+">
          <img class="price__foto" alt="Miami Surf School"
            :src="require('@/assets/img/foto-1.png')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="price__foto" alt="Miami Surf School"
            :src="require('@/assets/img/foto-1-mobile.png')">
          </mq-responsive>
          <span class="price__boat-capacity">capacity: up to 10 people</span>
        </div>
        <div class="price__cell price__cell--cell-1-2">
          <div class="price__label">weekdays</div>
          <mq-responsive target="small+">
          <img class="price__badge" alt="Miami Surf School"
            :src="require('@/assets/img/badge-1.webp')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="price__badge" alt="Miami Surf School"
            :src="require('@/assets/img/badge-1-mobile.webp')">
          </mq-responsive>

          <div class="price__price">
            <div class="price__price-row">
              <span class="price__type">1 HOUR</span>
              <span class="price__cost">260$</span>
            </div>
          </div>

          <div class="price__price">
            <div class="price__price-row price__price-row--row--2">
              <span class="price__type">1.5 HOUR</span>
              <span class="price__cost">390$</span>
            </div>
          </div>

          <div class="price__price">
            <div class="price__price-row price__price-row--row--3">
              <span class="price__type">2 HOUR</span>
              <span class="price__cost">520$</span>
            </div>
          </div>

        </div>
        <div class="price__cell price__cell--cell-1-3">
          <div class="price__label">weekends</div>
          <mq-responsive target="small+">
          <img class="price__badge" alt="Miami Surf School"
            :src="require('@/assets/img/badge-2.webp')">
          </mq-responsive>
          <mq-responsive target="mobile">
          <img class="price__badge" alt="Miami Surf School"
            :src="require('@/assets/img/badge-2-mobile.webp')">
          </mq-responsive>

          <div class="price__price">
            <div class="price__price-row">
              <span class="price__type">1 HOUR</span>
              <span class="price__cost">260$</span>
            </div>
          </div>

          <div class="price__price">
            <div class="price__price-row price__price-row--row--2">
              <span class="price__type">1.5 HOUR</span>
              <span class="price__cost">390$</span>
            </div>
          </div>

          <div class="price__price">
            <div class="price__price-row price__price-row--row--3">
              <span class="price__type">2 HOUR</span>
              <span class="price__cost">520$</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'PriceTag',
  components: {
    MqResponsive
  }
}
</script>

<style lang="scss">
@import '../assets/custom.scss';
.price{
  padding-top: 2.778%;
  @media (max-width: 599px){
    padding-top: 8%;
  }
  &__header{
    position: relative;
  }
  &__header-text{
    position: absolute;
    left: 0;
    top: 0;
    padding: 3.473% 0 0 5.556%;
    font-weight: 900;
    font-size: 59px;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(59);
    }
  }
  &__header-img{
    max-width: 100%;
    @media (max-width: 1440px){
      @include a-max-width(982);
    }
  }
  &__table{
    margin-top: -5.556%;
    padding-left: 3.473%;
    padding-right: 1%;
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 2.159%;
    padding-right: 1.439%;
    &--row--1 {
      margin-bottom: -4.317%;
    }
    &--row--2 {
      margin-bottom: -4.317%;
    }
    &--row--3 {
      margin-bottom: -4.317%;
    }
  }
  &__cell {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    &--cell-1-1 {
      align-items: flex-start;
      margin-top: 10.448%;
    }
    &--cell-2-1 {
      align-items: flex-start;
      margin-top: 8%;
    }
  }
  &__boat-name {
    display: block;
    font-size: 30px;
    line-height: 38px;
    font-weight: 900;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(30);
      @include a-line-height(38);
    }
    &--name-1{
      transform: rotate(-90deg) translate(-95px, -120px);
      @media (max-width: 1440px){
        @include a-transform(-95, -120);
      }
    }
  }
  &__foto{
    @media (max-width: 1440px){
      @include a-max-width(365);
    }
    @media (max-width: 599px){
      @include a-max-width-m(365, 10);
    }
  }
  &__boat-capacity {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;
    padding-top: 4.11%;
    @media (max-width: 1440px){
      @include a-font-size(17);
      @include a-line-height(20);
    }
    @media (max-width: 599px){
      @include a-font-size-m(17,1.5);
      @include a-line-height-m(20,1);
    }
  }
  &__badge{
    @media (max-width: 1440px){
      @include a-max-width(424);
    }
  }
  &__label {
    position: absolute;
    top: 18.5%;
    font-weight: 900;
    font-size: 41px;
    text-transform: uppercase;
    @media (max-width: 1440px){
      @include a-font-size(41);
    }
    @media (max-width: 599px){
      @include a-font-size-m(41, 2);
    }
  }
  &__price {
    position: absolute;
    top: 170px;
    width: 66.04%;
    @media (max-width: 1440px){
      @include a-top(170);
    }
    @media (max-width: 599px){
      width: 80.04%;
    }
    &--bottom{
      top: 530px;
      margin-top: 7%;
      @media (max-width: 1440px){
        @include a-top(530);
      }
    }
  }
  &__price-row {
    display: flex;
    &--row--2 {
      margin-top: 10%;
    }
    &--row--3 {
      margin-top: 20%;
    }
  }
  &__type {
    flex: 1;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: 1440px){
      @include a-font-size(24);
      @include a-line-height(29);
    }
    @media (max-width: 599px){
      @include a-font-size-m(24,2);
      @include a-line-height-m(29,1);
    }
  }
  &__cost {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: 1440px){
      @include a-font-size(24);
      @include a-line-height(28);
    }
    @media (max-width: 599px){
      @include a-font-size-m(24,2);
      @include a-line-height-m(28,1);
    }
  }
}
</style>
